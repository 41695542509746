import { deep } from '@theme-ui/presets'
import { mergeDeepRight } from 'ramda'

export default mergeDeepRight(deep, {
  styles: {
    spinner: {
      color: deep.colors.text,
    },
  },
})
